import { useTranslation } from 'next-i18next'
import React from 'react'
import { useCurrentOrganization } from '../data/organization'
import { cn } from '@/lib/utils'

const EmptyPublicChangelog: React.FC<{ whiteBg?: boolean }> = ({ whiteBg }) => {
  const { org } = useCurrentOrganization()
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div className="relative flex flex-col items-center justify-center mb-12 space-y-6">
        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="pt-12 font-bold text-gray-700 dark:text-white">
            {t('no-changes-published')}
          </h2>
          <p className="max-w-xs mt-2 text-gray-400 dark:text-foreground">
            {t('come-back-later-to-find-what-org-displayname-has-been-working-on', {
              displayName: org.displayName,
            })}
          </p>
        </div>
      </div>
      <div className="relative max-w-md mx-auto space-y-6">
        {!org?.settings?.customTheme?.enabled && (
          <div
            className={cn(
              'absolute z-10 -inset-4 mt-5 bg-gradient-to-b from-transparent to-[#F9FAFC] dark:from-transparent  dark:to-[#1A1E29]',
              whiteBg && 'to-white'
            )}
          ></div>
        )}
        <div className="relative">
          {/* <div className="absolute z-0 w-20 h-20 transform -skew-y-6 rounded-md skew-x-4 left-10 top-1 bg-gradient-to-bl from-secondary/30 to-secondary/20 " /> */}
          <div className="relative flex items-end w-full p-5 bg-white border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 dark:bg-secondary">
            <div className="">
              <div className="h-5 rounded-md w-52 bg-gray-500/10 dark:bg-border"></div>
            </div>
            <div className="flex ml-auto">
              <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-blue-600 to-sky-500"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative flex items-end w-full p-5 bg-white border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 dark:bg-secondary">
            <div className="">
              <div className="h-5 rounded-md dark:bg-border w-28 bg-gray-500/10"></div>
            </div>
            <div className="flex ml-auto">
              <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-purple-600 to-fuchsia-600"></div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative">
            <div className="relative flex items-end w-full p-5 bg-white border rounded-lg shadow-sm dark:shadow-lg border-gray-100/70 dark:border-border/20 dark:bg-secondary">
              <div className="">
                <div className="w-40 h-5 rounded-md dark:bg-border bg-gray-500/10"></div>
              </div>
              <div className="flex ml-auto">
                <div className="relative w-6 h-6 rounded-full bg-gradient-to-b from-yellow-500 to-orange-400"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyPublicChangelog
