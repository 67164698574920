import { useTranslation } from 'next-i18next'
import React, { useEffect, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { useChangelogsWithFiltering, useCurrentOrganization } from '../data/organization'
import { useUser } from '../data/user'
import { IChangelogFilters } from '../interfaces/IChangelog'
import ChangelogCTA from './ChangelogCTA'
import ChangelogEntry from './ChangelogEntry'
import EmptyPublicChangelog from './EmptyPublicChangelog'
import Loader from './Loader'
import PublicBoardWrapper from './PublicBoardWrapper'
import { sanitizeHTML } from '../lib/contentSanitizer'
import SeoMetaPublic from './SeoMetaPublic'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import { useAtom } from 'jotai'
import { lastViewedSubmissionAtom } from '@/atoms/displayAtom'
import { stripHtml } from 'string-strip-html'
import { isValidObjectId } from '@/lib/utils'

const truncateString = (string: string, length: number) => {
  return string.length > length ? string.substring(0, length) : string
}

const ChangelogPage: React.FC<{
  id?: string
  commentThreadId?: string
  initialFilters?: IChangelogFilters
}> = ({ id, commentThreadId, initialFilters }) => {
  const [lastViewedSubmission] = useAtom(lastViewedSubmissionAtom)
  const { org } = useCurrentOrganization()
  const { t, i18n } = useTranslation()
  const { user } = useUser()
  const router = useRouter()

  // Derive filters from router query parameters
  const filters: IChangelogFilters = useMemo(() => {
    const { c, q } = router.query
    const changelogCategories = c ? (Array.isArray(c) ? c : [c]) : []
    const searchQuery = typeof q === 'string' ? q : undefined

    const baseFilters: IChangelogFilters =
      initialFilters ||
      (id
        ? { [isValidObjectId(id) ? 'id' : 'slug']: id }
        : {
            sortBy: 'date:desc',
            state: 'live',
            limit: 10,
          })

    const newFilters: IChangelogFilters = {
      ...baseFilters,
      locale: i18n.language === 'default' ? 'en' : i18n.language,
    }
    if (!id) {
      if (changelogCategories.length > 0) {
        newFilters.changelogCategories = changelogCategories
      }

      if (searchQuery) {
        newFilters.q = searchQuery
      }
    }

    return newFilters
  }, [router.query.c, router.query.q, initialFilters, id, i18n.language])

  const {
    changelogResults,
    totalChangelogResults,
    size,
    setSize,
    changelogLoading,
    mutateChangelogs,
  } = useChangelogsWithFiltering(filters, org)

  const showLoader = totalChangelogResults ? size * 10 < totalChangelogResults : false

  const firstEntry = changelogResults ? changelogResults[0] : null
  const firstEntryDescription = changelogResults
    ? sanitizeHTML(stripHtml(changelogResults[0]?.content || '').result, false)
    : null

  useEffect(() => {
    if (user) {
      mutateChangelogs()
    }
  }, [user, mutateChangelogs])

  useEffect(() => {
    if (router?.query?.jwt) {
      // Remove the jwt from the URL
      const { jwt, ...newQuery } = router.query

      // Update the URL without the unwanted query param
      router.replace(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        undefined,
        { shallow: true }
      )
    }
  }, [router.query, router])

  // Function to update filters by updating the URL query parameters
  const setFilters = (updater: React.SetStateAction<IChangelogFilters>) => {
    const newFilters = typeof updater === 'function' ? (updater as Function)(filters) : updater
    const query: any = { ...router.query }

    // Update query parameters based on newFilters
    if (newFilters.changelogCategories && newFilters.changelogCategories.length > 0) {
      query.c = newFilters.changelogCategories
    } else {
      delete query.c
    }

    if (newFilters.q) {
      query.q = newFilters.q
    } else {
      delete query.q
    }

    // Update other query parameters as needed

    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    )
  }
  const locale = i18n.language === 'default' ? 'en' : i18n.language

  const changelogModuleName = useMemo(() => {
    const name = org?.publicPortalMenu?.find((item) => item.link === '/changelog')?.name
    const activeName = name?.[locale] || name?.[Object.keys(name || {})[0]]

    const showDefaultTranslation =
      !name?.[locale] &&
      ['changelog'].includes((activeName || 'changelog')?.toLowerCase().replace(' ', '-'))

    return (showDefaultTranslation ? t('changelog') : activeName) || 'Changelog'
  }, [org?.publicPortalMenu, locale])

  return (
    <PublicBoardWrapper mutateChangelogs={mutateChangelogs} module="changelog">
      <div className="-mt-8">
        {id && (
          <SeoMetaPublic
            hideName={true}
            page={changelogResults ? changelogResults[0]?.title : changelogModuleName}
            description={
              firstEntryDescription ? firstEntryDescription.substring(0, 150) + '...' : null
            }
            imageUrl={firstEntry?.featuredImage || ''}
            ogImageProps={
              (org &&
                !org.settings.private &&
                firstEntry && {
                  company: {
                    name: org?.displayName,
                    logo: org?.picture,
                    themeColor: org?.color,
                    themeLinePosition: 'bottom',
                  },
                  title: firstEntry?.title || changelogModuleName,
                  description: truncateString(firstEntryDescription || '', 150),
                  type: 'root',
                }) ||
              undefined
            }
          />
        )}

        {!id ? (
          <div className="max-w-5xl border-b py-[69px] sm:py-20 mx-auto flex-col relative">
            <h1 className="text-2xl font-semibold text-gray-600 sm:text-3xl dark:text-gray-50">
              {changelogModuleName}
            </h1>
            <p className="max-w-xl mt-3 text-sm sm:text-sm">
              {t('follow-new-updates-and-improvements-to-org-displayname', {
                displayName: org.displayName,
              })}
              .
            </p>
            <div className="flex items-end justify-between mt-4">
              <ChangelogCTA filters={filters} setFilters={setFilters} id={id} user={user} />
            </div>
            <svg
              className="absolute md:block hidden top-[80px] right-4 w-12 h-12 text-gray-200 lg:h-14 lg:w-14 opacity-[35%] dark:opacity-50 dark:text-background-accent/60"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1 2.4C11.4 4.5 2.8 33 3.4 34.6C4.2 36.6 7.1 36.3 9.6 33.9C11.7 31.9 12.3 31.9 20.7 32.9C34.3 34.6 34.8 34.5 36.4 30.7C38.6 25.3 39.3 22.7 38.3 23.3C37.8 23.6 36.7 25.8 36 28.1L34.6 32.3L22.7 31.6C12.6 31 10.5 31.1 8.3 32.5C7 33.4 5.6 33.9 5.3 33.6C5 33.3 5.7 30.5 6.8 27.3C8 24.1 10 17.6 11.5 12.8C14.3 3.2 15.7 1.3 16.6 5.9C17.3 9.4 22.9 14.9 29 18C33.1 20.1 33.7 20.9 32.1 22.5C31.7 23.1 31 24.8 30.6 26.5C30 29.4 30 29.4 31.5 27.5C32.4 26.4 33.3 24.5 33.6 23.3C34 21.8 34.7 21.3 35.8 21.7C38.5 22.7 37 21.5 28.9 16.2C20.5 10.7 20 10.1 18.5 5C17.4 1.2 15 0.0999947 13.1 2.4Z"
                fill="currentColor"
              />
              <path
                className="text-accent"
                d="M1.4 14.6C-0.7 17.7 0.5 22.3 3.4 22.8C5.2 23.2 5.3 23.1 3.7 21.5C0.7 18.6 2.2 14.5 7 12.7C7.9 12.4 7.3 12.1 5.8 12.1C3.9 12 2.5 12.8 1.4 14.6Z"
                fill="currentColor"
              />
              <path
                d="M42.8 24.2C45.6 25.6 46.6 29.3 45.1 32.6C44.2 34.6 43.3 35 40 35C37.5 35 36.1 35.4 36.5 36C36.8 36.5 38.9 37 41 37C45.5 37 48 34.4 48 29.8C48 26.6 44.9 23 42.3 23.1C40.9 23.1 41 23.4 42.8 24.2Z"
                fill="currentColor"
              />
              <path
                d="M17 35.5C17 36.4 18.1 37.5 19.5 38C21.7 38.8 21.8 39.2 20.8 42C19.6 45.6 19.7 45.8 23.6 46.5C26.2 46.9 26.8 46.5 29.8 41.8C34.2 34.9 33.4 33.4 28.7 39.8C26.1 43.4 24.6 44.8 23.5 44.3C22.5 44 22.1 43.1 22.5 42.1C22.9 41.2 23.3 38.9 23.6 37C23.8 35.1 23.7 34.3 23.4 35.2C22.7 37.4 19 37.6 19 35.5C19 34.7 18.6 34 18 34C17.5 34 17 34.7 17 35.5Z"
                fill="currentColor"
              />
            </svg>
          </div>
        ) : (
          <div className="relative flex-col max-w-5xl py-8 mx-auto border-b">
            <button
              onClick={() => {
                if (lastViewedSubmission) {
                  router.back()
                } else {
                  router.push('/changelog')
                }
              }}
              className="dashboard-secondary"
            >
              <ArrowLeftIcon className="w-4 h-4 mr-1.5 secondary-svg" />
              {t('back-to-other-posts')}
            </button>
          </div>
        )}
        <div className="w-full pt-8 sm:pt-12">
          <div className="flex flex-col gap-12 divide-y sm:gap-20 dark:divide-border">
            {changelogResults?.length === 0 && <EmptyPublicChangelog whiteBg={true} />}

            {changelogResults &&
              changelogResults.map((changelog, i) => {
                return (
                  <div
                    key={changelog.id}
                    className={`${
                      i !== 0 && 'pt-12 sm:pt-20'
                    } border-gray-100/50 dark:border-secondary/30`}
                  >
                    <ChangelogEntry
                      featuredImage={changelog.featuredImage}
                      modifiedParsedContent={changelog.content}
                      id={changelog.id}
                      singlePage={!!id}
                      categories={changelog.changelogCategories}
                      changelog={changelog}
                      content={changelog.content || ''}
                      title={changelog.title || ''}
                      date={changelog.date ? new Date(changelog.date).toISOString() : ''}
                      mutateChangelogs={mutateChangelogs}
                      nth={i}
                      commentThreadId={commentThreadId}
                    />
                  </div>
                )
              })}
          </div>
          <div className="flex max-w-5xl mt-16">
            <div className="md:w-[200px] md:block hidden"></div>
            <div className="flex items-center justify-center w-full max-w-3xl">
              {changelogLoading && (
                <div>
                  <div className="!w-6 !h-6 mx-auto secondary-svg">
                    <Loader />
                  </div>
                </div>
              )}

              {!changelogLoading && showLoader && (
                <InView
                  as="div"
                  onChange={(inView: boolean) => {
                    inView && setSize(size + 1)
                  }}
                >
                  <div className="!w-6 mt-4 !h-6 secondary-svg">
                    <Loader />
                  </div>
                </InView>
              )}
            </div>
          </div>
        </div>
      </div>
    </PublicBoardWrapper>
  )
}

export default ChangelogPage
